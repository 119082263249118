import { http, authHeader } from '@/http-commons';

class EntradaEvidenceFileService {

    uploadEvidence(evidence, entradaId) {
        const formData = new FormData();
        formData.append('evidence', evidence);
        return http.post(`/api/v1/entradaEvidenceFile/uploadEvidence/${entradaId}`, formData, { headers: authHeader() });
    }

    table( params, entradaId ) {
        return http.get(`api/v1/entradaEvidenceFile/table/${entradaId}`, { params, headers : authHeader() });
    }

}

export default new EntradaEvidenceFileService()