<template>
    <div>
        <v-chip class="calendario font-weight-medium text-center" color="pink darken-2" label text-color="white">
            <v-icon left class="ml-2">fas fa-credit-card</v-icon>
            <p class="text-uppercase mt-4 white--text font-weight-bold text-h7">
                Credito: {{ providerCredit?.nombre || '' }}
            </p>
        </v-chip>
        <v-simple-table fixed-header height="auto" dense class="mt-1">
            <thead>
                <th></th>
                <th></th>
            </thead>
            <tbody class="bg-secondary">
                <tr>
                    <td class="font-weight-black">Dias de credito</td>
                    <td class="font-weight-bold">{{ providerCredit.diasCredito }}</td>
                </tr>
                <tr>
                    <td class="font-weight-black">Documento permitido</td>
                    <td class="font-weight-bold">{{ providerCredit.documentoCredito }}</td>
                </tr>
                <tr>
                    <td class="font-weight-black">Limite credito</td>
                    <td class="font-weight-bold">{{ providerCredit.limiteCreditoNatural }}</td>
                </tr>
                <tr>
                    <td class="font-weight-black red--text">Documento pendiente</td>
                    <td class="font-weight-black red--text">{{ providerCredit.numberDocument }}</td>
                </tr>
                <tr>
                    <td class="font-weight-black red--text">Cuentas pendientes</td>
                    <td class="font-weight-black red--text">{{ providerCredit.debtNatural }}</td>
                </tr>
                <tr>
                    <td class="font-weight-black green--text">Saldo disponible</td>
                    <td class="font-weight-black green--text">{{ providerCredit.balanceNatural }}</td>
                </tr>
            </tbody>
        </v-simple-table>
    </div>
</template>
<script>
import proveedorDataService from '@/service/catalogo/proveedor/proveedorDataService';

export default {
    name: 'DetailproviderCredit',
    props: {
        providerId: { type: Number, required: true }
    },
    data() {
        return {
            providerCredit: {},
            allowToSell: false
        }
    },
    methods: {
        getProviderCredit() {
            if (this.providerId) {
                proveedorDataService.getDetailCredit(this.providerId).then( result => {
                    this.providerCredit = result.data
                    this.$emit('providerCredit', this.providerCredit)

                })
            }
        }
    },
    watch: {
        providerId: {
            immediate: true,
            handler() {
                this.getProviderCredit();
            }
        }
    }
}
</script>