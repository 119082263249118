<template>
    <v-card flat style="border: 1px solid #e0e0e0; background-color: #fafafa;">
        <v-card-title class="pa-1 pl-4 blue-grey darken-1 white--text text-subtitle-1 font-weight-bold">
            Resumen de la entrada: {{ item.ticketFolio }}
        </v-card-title>
        <v-card-text class="py-1">
            <v-row>
                <v-col cols="12" sm="6" md="2" class="mt-0">
                    <strong>Tipo de entrada:</strong> {{ item.tipoEntrada }}
                </v-col>
                <v-col cols="12" sm="6" md="2" class="mt-0">
                    <strong>Precio:</strong> {{ item.precioUnitarioNatural }}
                </v-col>
                <v-col cols="12" sm="6" md="2" class="mt-0">
                    <strong>Subtotal:</strong> {{ item.importeNatural }}
                </v-col>
                <v-col cols="12" sm="6" md="2" class="mt-0">
                    <strong>Estatus:</strong> {{ item.activo ? 'Activo' : 'Inactivo' }}
                </v-col>
                <v-col cols="12" sm="6" md="2" class="mt-0">
                    <strong>Peso promedio:</strong> {{ item.averageWeight }}
                </v-col>
                <v-col cols="12" sm="6" md="2" class="mt-0">
                    <strong>Peso neto:</strong> {{ item.netWeight }}
                </v-col>
            </v-row>
            <hr />
            <v-row>
                <v-col cols="12" sm="6" md="4">
                    <strong>Proveedor:</strong> {{ item.proveedor?.razonSocial }}
                </v-col>
                <v-col cols="12" sm="6" md="2" class="mt-0">
                    <strong>Forma de pago:</strong> {{ item.tipoPago }}
                </v-col>
                <v-col cols="12" sm="6" md="2" class="mt-0">
                    <strong>Total:</strong> {{ item.entrada.totalNatural }}
                </v-col>
                <v-col cols="12" sm="6" md="2" class="mt-0">
                    <strong>Abonado:</strong> {{ item.entrada.abonadoNatural }}
                </v-col>
                <v-col cols="12" sm="6" md="2" class="mt-0">
                    <strong>Saldo:</strong> {{ item.entrada.saldoNatural }}
                </v-col>
            </v-row>
            <hr />
        </v-card-text>
    </v-card>
</template>
<script>
export default {
    name: "EntryDetail",
    props: {
        item: { type: Object },
    }
}
</script>