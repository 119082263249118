<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" persistent transition="scale-transition" max-width="1020" width="920"
        class="mx-auto my-12 overflow-hidden"
        :loading="pdfSource === ''" >
            <v-card class="elevation-1 overflow-hidden">
                <v-toolbar dark color="primary" text="white">
                    <v-btn icon dark @click.native="handlePdfModal">
                        <v-icon>fas fa-times-circle</v-icon>
                    </v-btn>
                    <v-toolbar-title>PREVISUALIZAR {{ titlePdf }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="iframe-container">
                    <iframe :src="pdfSource" style="border: none;"></iframe>
                </v-card-text>
                <v-card-actions>
                    <v-list-item class="grow">
                        <v-row justify="start" align="center">
                            <v-btn color="red lighten-1" dark @click.native="handlePdfModal" class="mr-2">
                                <v-icon dark left>fas fa-xmark</v-icon>Cerrar
                            </v-btn>
                            <v-btn color="blue darken-4" dark @click="downloadPDF">
                                <v-icon dark left>fas fa-file-pdf</v-icon>Descargar
                            </v-btn>
                        </v-row>
                    </v-list-item>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    name: "PreviewPDF",
    props: {
        titlePdf: { type: String, default: "PDF" },
        pdfSource: { type: String, default: "" },
        pdfBlob: { type: Blob, default: null },
        dialog: { type: Boolean, default: false },
        handlePdfModal: { type: Function }
    },
    methods: {
        downloadPDF() {
            if (this.pdfBlob) {
                const url = URL.createObjectURL(this.pdfBlob)
                const a = document.createElement('a')
                a.href = url
                a.download = 'document.pdf'
                document.body.appendChild(a)
                a.click()
                document.body.removeChild(a)
                URL.revokeObjectURL(url)
            }
        }
    }
}
</script>

<style scoped>
.v-dialog {
    overflow-y: hidden !important;
}
.iframe-container {
    position: relative;
    width: 100%;
    height: 700px;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}
.iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
}
</style>