<template>
    <v-row align="center" justify="center">
        <v-col cols="12" sm="12" md="12">
            <v-card class="elevation-4">
                <v-card-title>
                    <v-toolbar flat>
                        <v-btn small dark color="primary" @click="checkIfHasSucursal">
                            <v-icon left>fas fa-plus-square</v-icon>Nueva entrada
                        </v-btn>
                        <v-spacer></v-spacer>
                        <SingleCalendar refElement="activeInicio" :input-date.sync="datesRange.start" title="Fecha inicio"/>
                        <SingleCalendar refElement="activeFin" :input-date.sync="datesRange.end" title="Fecha fin" :min-date="datesRange.start"/>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-spacer></v-spacer>
                    <v-col cols="12" md="12" sm="12" xl="6" class="d-flex justify-end">
                        <v-text-field v-model="search" append-icon="fas fa-search"
                        label="Buscar por folio de entrada" single-inline hide-details
                        @keydown.enter="searchListaEntrada">
                        </v-text-field>
                        <v-btn icon class="mt-4 ml-2">
                            <v-icon dense>fas fa-sync</v-icon>
                        </v-btn>
                        <v-tooltip left color="success" >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn icon class="mt-4 ml-2" color="success" v-bind="attrs" v-on="on" dark @click="handleExcelExport">
                                    <v-icon dark left>fas fa-file-export</v-icon>
                                </v-btn>
                            </template>
                            <span>Reporte de entradas</span>
                        </v-tooltip>
                        <Columns :headers.sync="headers"></Columns>
                    </v-col>
                </v-card-title>
                <v-data-table dense :headers="filteredHeaders" :items="lstEntrada" class="elevation-2"
                :loading="loading" loading-text="Cargando... espera un momento" :options.sync="options"
                :footer-props="{ 'items-per-page-options': [ 25, 50, 100 ],
                    showFirstLastPage: true,
                    firstIcon: 'fas fa-angle-double-left',
                    lastIcon: 'fas fa-angle-double-right',
                    prevIcon: 'fas fa-angle-left',
                    nextIcon: 'fas fa-angle-right'
                }"
                :single-expand="singleExpand" :expanded.sync="expanded" show-expand
                :server-items-length="totalListaEntrada">
                    <!-- group-by="producto.nombre"  -->
                    <template v-slot:expanded-item="{ headers, item }">
                        <td :colspan="headers.length" class="pa-0">
                            <EntryDetail :item="item"></EntryDetail>
                        </td>
                    </template>

                    <template v-slot:[`item.producto`]="{ item }">
                        <v-chip :color="item.color" label small>
                            {{ item.producto }}
                        </v-chip>
                    </template>
                    <template v-slot:[`item.prioridad`]="{ item }">
                        <v-chip :color="getColorPriority(item.prioridad)" dark label small text-color="black">
                            <v-avatar left>
                                <v-icon small>fas fa-exclamation</v-icon>
                                <strong class="text-h6 font-weight-bold">{{ item.prioridad }}</strong>
                            </v-avatar>
                        </v-chip>
                    </template>
                    <template v-slot:[`item.merma`]="{ item }">
                        <strong :class="item.merma > 0 ? 'red--text text--lighten-1 text-h6' : 'green--text text--lighten-1 text-h7 '">{{item.merma}}</strong>
                    </template>
                    <template v-slot:[`item.venta`]="{ item }">
                        <strong class="green--text text--lighten-1 text-h6">{{item.venta}}</strong>
                    </template>
                    <template v-slot:[`item.existencia`]="{ item }">
                        <strong :class="item.existencia == 0 ? 'red--text text--lighten-1 text-h6' : 'green--text text--lighten-1 text-h7 '">{{item.existencia}}</strong>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                       <ActionsEntrada :item="item"></ActionsEntrada>
                    </template>
                    <template v-slot:[`item.activo`]="{ item }">
                        <v-chip :color="colorStatus(item)" label dark x-small
                        v-text="statusEntrada(item)">
                        </v-chip>
                    </template>
                </v-data-table>
            </v-card>
        </v-col>
        <ModalNuevaEntrada :dialog.sync="dialog" :initialize.sync="initTableEntrada" :sucursales.sync="sucursales" :tipoEntrada.sync="filterTipoEntrada"
        :proveedores.sync="proveedores" :tipoPagos.sync="tipoPagos" :monedas.sync="monedas" :productos.sync="productos"
        :handleModal="handleModal" v-if="dialog">
        </ModalNuevaEntrada>
        <ExcelExportEntryDetail :dialog.sync="dialogEntryReportExcel" :handleModal="handleExcelExport"
        :typeEntry.sync="tipoEntrada" :providers.sync="proveedores"
        :brands.sync="sucursales" :is-admin="isAdmin" v-if="dialogEntryReportExcel"/>
    </v-row>
</template>
<script>
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import TipoEntradaService from '@/service/entrada/TipoEntradaService';
import ProveedorDataService from '@/service/catalogo/proveedor/proveedorDataService';
import TipoPagoDataService from '@/service/catalogo/tipoPago/tipoPagoDataService';
import MonedaDataService from '@/service/catalogo/tipoMoneda/tipoMonedaDataService';
import ProductoDataService from "@/service/producto/ProductoDataService";
import EntradaDetalleDataService  from '@/service/entrada/EntradaDetalleDataService'

import { getParamsPagination } from '@/utils/pagination';
import ModalNuevaEntrada from './NuevaEntrada.vue'
import ActionsEntrada from './ActionsEntrada.vue';
import SingleCalendar from '@/components/shared/SingleCalendar.vue';
import Columns from '@/components/shared/Columns.vue';
import ExcelExportEntryDetail from './ExcelExportEntryDetail.vue';
import UsuarioDataService from '@/service/usuario/usuarioDataService';
import EntryDetail from './EntryDetail.vue';

export default {
    name: "ListaEntrada",
    components: { ModalNuevaEntrada, SingleCalendar, ActionsEntrada, ExcelExportEntryDetail, Columns, EntryDetail },
    created() {
        this.getAllSucursal();
        this.getAllTipoEntrada();
        this.getProveedores();
        this.getAllTipoPago();
        this.getMoneda();
        this.getAllProducto();
        this.initTableEntrada();
    },
    props: {
        proveedorId: { type: Number, default: null },
        typeEntry: { type: String, default: '' },
    },
    data() {
        return {
            sucursales : [],
            tipoEntrada: [],
            proveedores: [],
            tipoPagos: [],
            monedas: [],
            productos: [],
            datesRange:{ start: '', end: '' },
            lstEntrada: [],
            options: {},
            totalListaEntrada: 0,
            loading: true,
            search: '',

            dialog: false,
            expanded: [],
            singleExpand: true,
            headers: [
                { text: "Acciones", value: "actions", sortable: false, groupable: false, show: true },
                { text: "ID", align: " d-none", sortable: false, value: "id", show: false, groupable: false },
                { text: "Folio entrada", value: "ticketFolio", groupable: false, show: true },
                { text: "Fecha entrada", value: "fechaEntrada", groupable: false, show: true },
                { text: "Tipo entrada", value: "tipoEntrada", groupable: false, show: true },
                { text: 'Sucursal', value:'sucursalRecibe', groupable: false, show: true },
                { text: "Prioridad", value: "prioridad", groupable: false, show: true },
                { text: "Producto", value: "producto.nombre", sortable: false, show: true },
                { text: "Ingreso", value: "cantidad", groupable: false, show: true },
                // { text: "Entrada por traspaso", value: "totalEntradaTraspaso" },
                { text: "Salida traspaso", value: "totalSalidaTraspaso", groupable: false, show: true },
                { text: "Mermas", value: "merma", groupable: false, show: true },
                { text: "Ventas", value: "totalSalida", groupable: false, show: true },
                { text: 'Existencia', value: 'existencia', groupable: false, show: true },
                { text: 'Peso promedio', value: 'averageWeight', groupable: false, show: false },
                { text: 'Peso neto', value: 'netWeight', groupable: false, show: false },
                { text: "Activo", value: "activo", groupable: false, show: true },
                { text: 'Tipo pago', value: 'tipoPago', groupable: false, show: false },
                { text: 'Proveedor', value: 'proveedor.nombre', groupable: false, show: false },
            ],
            // Entry report
            dialogEntryReportExcel: false,
        }
    },
    methods: {
        searchListaEntrada(){
            this.options.page = 1;
            this.initTableEntrada(this.options);
        },
        initTableEntrada(options = {}) {
            this.loading = true;
            let params = getParamsPagination({ options, search: this.search,
                params: {
                    startDate: this.datesRange.start, endDate: this.datesRange.end, providerId: this.proveedorId, typeEntry: this.typeEntry,
                }
            });
            EntradaDetalleDataService.getEntradaDetalleTable(params).then( resp => {
                const { rows, totalElements } = resp.data;
                this.lstEntrada = rows;
                this.totalListaEntrada = totalElements;
            }).catch( err => {
                this.$swal.fire({ icon: 'error', title: 'Falló la carga de información', text: err.response.data, timer: 5000 });
            }).finally( () => {
                this.loading = false;
            })
        },
        detalleEntrada ( item ){
            console.log("detalle de compra");
        },
        colorStatus( item ){ return (item.status || item.existencia > 0 ) ? "green" : "red"; },
        statusEntrada( item ){ return (item.status || item.existencia > 0 ) ? "Activo" : "Inactivo"; },
        getColorPriority( prioridad ){
            const colourPriority = { 1: 'green', 2: 'yellow', 3: 'warning', 4: 'blue' };
            return colourPriority[prioridad] || 'grey';
        },
        async getAllSucursal(){
            this.sucursales = await SucursalDataService.lstSucursal().then( rsp => rsp.data ) || [];
        },
        async getAllTipoEntrada(){
            this.tipoEntrada = await TipoEntradaService.getAllTipoEntrada().then( rsp => rsp.data ) || [];
        },
        async getProveedores(){
            this.proveedores = await ProveedorDataService.allProveedor().then( rsp => rsp.data ) || [];
        },
        async getAllTipoPago(){
            this.tipoPagos = await TipoPagoDataService.allTipoPago().then( rsp => rsp.data ) || [];
        },
        async getMoneda(){
            this.monedas = await MonedaDataService.getAllTipoMoneda().then( resp => resp.data ) || [];
        },
        async getAllProducto(){
            this.productos = await ProductoDataService.findAll().then( rsp => rsp.data ) || [];
        },
        handleModal(){ this.dialog = !this.dialog; },
        checkIfHasSucursal(){
            if(!this.hasSucursal){
                this.$swal.fire({ icon: 'info', title: 'Asigna una sucursal', text: 'Favor de asignar una sucursal a tu usuario', showConfirmButton: false, timer: 3500 });
                return;
            }
            this.handleModal();
        },
        handleExcelExport(){ this.dialogEntryReportExcel = !this.dialogEntryReportExcel },
    },
    watch: {
        options: {
            handler( val, oldVal ) {
                if (val.page !== oldVal.page || val.itemsPerPage !== oldVal.itemsPerPage) {
                    this.initTableEntrada(val);
                }
            }, deep: true
        },
        'datesRange.start': function( val, oldVal ) {
            if (val !== oldVal && this.datesRange.end != null && this.datesRange.end != '') {
                this.initTableEntrada(this.options);
            }
        },
        'datesRange.end': function( val, oldVal ) {
            if (val !== oldVal && this.datesRange.start != null && this.datesRange.start != '') {
                this.initTableEntrada(this.options);
            }
        },
        'proveedorId': function( val, oldVal ) {
            if (val !== oldVal) {
                this.initTableEntrada(this.options);
            }
        },
        'typeEntry': function( val, oldVal ) {
            if (val !== oldVal) {
                this.initTableEntrada(this.options);
            }
        },

    },
    computed: {
        filterTipoEntrada() { return this.tipoEntrada.filter( te => te.id !== 3 ); },
        filteredHeaders() { return this.headers.filter(h => h.show); },
        hasSucursal(){
            const sucursal = SucursalDataService.getCurrentSucursal();
            return sucursal != undefined || sucursal != null;
        },
        isAdmin(){ return UsuarioDataService.getUserRoles().includes('ROLE_ADMIN'); },
    }
}
</script>