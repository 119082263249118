<template>
    <v-dialog v-model="dialog" fullscreen persistent hide-overlay transition="dialog-bottom-transition">
        <v-card outlined shaped class="elevation-4">
            <ValidationObserver ref="refEntrada" v-slot="{ invalid, validated }">
                <v-form name="form" @submit.prevent="saveEntrada">
                    <v-toolbar dark color="primary">
                        <v-btn icon dark @click.native="handleModal">
                            <v-icon>fas fa-times-circle</v-icon>
                        </v-btn>
                        <v-toolbar-title>Nueva entrada de productos</v-toolbar-title>
                        
                        <v-spacer></v-spacer>
                        <v-toolbar-items>
                            <v-btn class="primary" type="submit" :disabled="invalid || !validated">
                                <v-icon class="ma-2" large left>fas fa-save</v-icon>Guardar entrada
                            </v-btn>
                        </v-toolbar-items>
                    </v-toolbar>
                    <v-card-text>
                        <v-container fluid>
                            <v-row>
                                <v-col  cols="9" md="9" sm="12">
                                    <v-row wrap>
                                        <v-col cols="12" xs="12" md="3">
                                            <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                                <v-autocomplete :items="tipoEntrada" item-text="nombre" item-value="id" filled outlined
                                                v-model="entrada.tipoEntradaId" label="Tipo de entrada" required prepend-inner-icon="fas fa-gopuram"
                                                :error-messages="errors" :success="valid">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" md="4" v-if="modeBuy">
                                            <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                                <v-autocomplete :items="sucursales" item-text="nombre" item-value="id" filled outlined
                                                v-model="entrada.sucursalRecibeId" label="Sucursal recibe*" required prepend-inner-icon="fas fa-store-alt"
                                                hint="Seleccione la sucursal que recibe" :readonly="!isAdmin"
                                                :error-messages="errors" :success="valid">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
        
                                        <v-col cols="12" xs="12" md="4" v-if="modeTraspaso">
                                            <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                                <v-autocomplete :items="sucursales" item-text="nombre" item-value="id" filled outlined
                                                v-model="entrada.sucursalRecibeId" label="Sucursal recibe" required prepend-inner-icon="fas fa-store-alt"
                                                :error-messages="errors" :success="valid" :readonly="!isAdmin">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" md="5" v-if="modeTraspaso">
                                            <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                                <v-autocomplete v-model="entrada.origenTrasladoId" :items="ventasTraslado" item-text="ticketFolio" item-value="id"
                                                label="Folio salida traslado" clearable chips
                                                required prepend-inner-icon="fas fa-building" filled outlined
                                                persistent-hint single-line :error-messages="errors" :success="valid">
                                                <!-- hide-details hide-selected -->
                                                    <template v-slot:item="{ item, data }">
                                                        <p>{{ data }}</p>
                                                        <v-list-item-avatar>
                                                            <v-icon>fas fa-drumstick-bite</v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{ item.ticketFolio }} - {{ item.registerDate }} - 
                                                                <strong>Envia: {{ item.sucursalEnvia.nombre }}</strong>
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                    <template v-slot:selection="{ attr, on, item, selected }">
                                                        <v-chip v-bind="attr" :input-value="selected" color="blue-grey"
                                                        class="white--text" v-on="on">
                                                            <v-icon left>fas fa-drumstick-bite</v-icon>
                                                            <span v-text="item.ticketFolio +' - '+ item.registerDate"></span>
                                                        </v-chip>
                                                    </template>
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <!-- <v-col cols="12" xs="12" md="3" v-if="modeTraspaso">
                                            <v-autocomplete :items="sucursales" item-text="nombre" item-value="id" filled outlined
                                            v-model="entrada.sucursalEnviaId" label="Sucursal envia" required prepend-inner-icon="fas fa-store-alt">
                                            </v-autocomplete>
                                        </v-col> -->
                                        <v-col cols="12" xs="12" md="5" v-if="modeBuy">
                                            <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                                <v-autocomplete :items="proveedores" item-text="razonSocial" item-value="idProveedor" filled outlined
                                                v-model="entrada.proveedorId" label="Proveedor" required clearable prepend-inner-icon="fas fa-truck"
                                                hint="Seleccione un proveedor" :error-messages="errors" :success="valid">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" md="3" v-if="modeBuy">
                                            <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                                <v-autocomplete :items="tipoPagos" item-text="formaPago" item-value="id" filled outlined
                                                v-model="entrada.tipoPagoId" label="Forma de Pago" required prepend-inner-icon="fas fa-money-check-alt"
                                                :error-messages="errors" :success="valid">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" xs="12" md="4" v-if="modeBuy">
                                            <ValidationProvider rules="required" v-slot="{ errors, valid}" :immediate="true">
                                                <v-autocomplete :items="monedas" item-text="moneda" item-value="id" filled outlined
                                                v-model="entrada.tipoMonedaId" label="Moneda*" required prepend-inner-icon="fas fa-coins"
                                                :error-messages="errors" :success="valid">
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <!-- add evidences -->
                                        <v-col cols="12" xs="12" md="5">
                                            <template>
                                                <v-file-input v-model="fileEvidences" color="deep-purple accent-4" counter
                                                    label="Adjuntar archivos" :multiple="false" placeholder="Seleccione los archivos" prepend-icon="fas fa-paperclip"
                                                    outlined dense>
                                                        <template v-slot:selection="{ index, text }">
                                                            <v-chip v-if="index < 2" color="deep-purple accent-4" dark label small >
                                                                {{ text }}
                                                            </v-chip>
                                                            <span v-else-if="index === 2" class="text-overline grey--text text--darken-3 mx-2">
                                                                +{{ files.length - 2 }} Archivos
                                                            </span>
                                                        </template>
                                                </v-file-input>
                                            </template>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" sm="12" md="3" lg="3" class="hidden-sm-only hidden-xs-only">
                                    <v-row wrap>
                                        <v-col cols="12" sm="12" md="12">
                                            <v-alert class="calendario" x-small icon="fas fa-calendar-check" prominent type="success" color="primary">
                                                {{computedDate}}
                                            </v-alert>
                                            <DetailCreditProvider :provider-id="entrada.proveedorId" v-if="modeBuy && entrada.proveedorId"
                                            @providerCredit="onProviderCredit"/>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <addProductoEntrada ref="myEntryProduct" :products.sync="productos"
                            :tipoEntrada.sync="entrada.tipoEntradaId"
                            :entrada.sync="entrada"
                            @addEntradaProduct="addEntradaProduct">
                            </addProductoEntrada>
                        </v-container>
                        <small>* Indica son campos requeridos</small>
                        <v-col cols="12" sm="12" md="12" justify="center">
                            <v-chip class="mb-4 mt-4 text-h3 font-weight-regular white--text filterSubtotal" color="primary" label text-color="white" large>
                                <v-icon left>fas fa-dollar-sign</v-icon>
                                {{totalNatural}}
                            </v-chip>
                        </v-col>
                    </v-card-text>
                </v-form>
            </ValidationObserver>
        </v-card>
    </v-dialog>
</template>

<script>
import EntradaDataService from '@/service/entrada/EntradaDataService';
import VentaDataService from '@/service/venta/VentaDataService';
import VentaDetalleDataService from '@/service/venta/VentaDetalleDataService';
import addProductoEntrada from './addProductEntry.vue'
import SucursalDataService from '@/service/catalogo/sucursal/sucursalDataService';
import { getFullDateTodayNatural, getHourNowNatural } from '@/utils/DateUtil';
import UsuarioDataService from '@/service/usuario/usuarioDataService';
import EntradaEvidenceFileService from '@/service/entrada/EntradaEvidenceFileService';
import DetailCreditProvider from '../proveedor/DetailCreditProvider.vue';
export default {
    name: "NuevaEntrada",
    components : { addProductoEntrada, DetailCreditProvider },
    props: {
        dialog:{ default : false, type : Boolean },
        handleModal : { type: Function },
        initialize : { type: Function },

        sucursales : { type: Array },
        usuarios : { type: Array },
        tipoEntrada : { type: Array },
        proveedores : { type: Array },
        tipoPagos : { type: Array },
        monedas : { type: Array },
        productos : { type: Array }
    },
    created(){
        this.entrada.sucursalRecibeId = SucursalDataService.getCurrentSucursalId();
    },
    data() {
        return {
            ventasTraslado: [],
            entrada: {
                idEmpleado: null,
                sucursalEnviaId: null,
                sucursalRecibeId: SucursalDataService.getCurrentSucursalId(),
                origenTrasladoId: null,
                proveedorId: null,
                tipoEntradaId: 1,
                tipoPagoId: 1,
                tipoMonedaId: 1,
                notas: '',
                total: 0,
                eliminado : false,
                entradaProduct: [],
            },
            creditProvider: {},
            fileEvidences: undefined,
            totalNatural: 0
        }
    },
    methods: {
        changeTipoEntrada(){
            this.entrada.sucursalRecibeId = SucursalDataService.getCurrentSucursalId();
            this.$refs.myEntryProduct.entradaProduct = [];
        },
        listVentaTraslado(sucursalRecibeId){
            this.entrada.origenTrasladoId = null;
            VentaDataService.ventaTrasladoEnviado(sucursalRecibeId).then(response => {
                this.ventasTraslado = response.data;
            }).catch( error => {
                this.$swal.fire({ position: 'top-end', icon: 'error', html: error.response.data, title: 'Error al cargar la lista de traslados', showConfirmButton: false, timer: 2500 });
            });
        },
        chargeProductToEntry(origenTrasladoId){
            const sucursalEnvia = this.ventasTraslado.find( item => item.id == origenTrasladoId );
            this.entrada.sucursalEnviaId = sucursalEnvia?.sucursalEnvia?.id ?? null;
            VentaDetalleDataService.showDetailsVenta(origenTrasladoId).then(response => {
                const ventaTraspaso = response.data;
                console.log({ventaTraspaso});
                let data = ventaTraspaso.map( item => {
                    return {
                        productoId: item.producto.id,
                        cantidad: item.cantidad,
                        precioUnitario: item.precioVenta,
                        netWeight: item.kg,
                        // TODO: check if it is right to do this
                        importe: parseFloat(item.kg * item.precioVenta).toFixed(2),
                        averageWeight: parseFloat(item.kg / item.cantidad).toFixed(2),
                        activo: true,
                        eliminado: false
                    }
                })
                this.$refs.myEntryProduct.entradaProduct = data;
            }).catch( error => {
                this.$swal.fire({ position: 'top-end', icon: 'error', text: error.response.data, title: 'Error al cargar los productos', showConfirmButton: false, timer: 2500 });
            });
        },
        saveEntrada(){
            this.$refs.refEntrada.validate().then( isValid => {
                if( this.entrada.entradaProduct == null || this.entrada.entradaProduct.length == 0){
                    this.$swal.fire({ position: 'top-end', icon: 'error', text: 'Debe agregar al menos un producto', title: 'Error al guardar la entrada', showConfirmButton: false, timer: 2500 });
                    return;
                }
                if( isValid ){
                    EntradaDataService.create( this.entrada ).then( resp => {
                        if( resp.status == 200){
                            if(this.fileEvidences != undefined){
                                const entradaId = resp.data.id;
                                EntradaEvidenceFileService.uploadEvidence( this.fileEvidences, entradaId ).then( resp => {
                                    this.handleModal();
                                    this.initialize();
                                    this.$swal.fire({ position: 'top-end', icon: 'success', title: 'Entrada creada correctamente', text: resp.data.message, showConfirmButton: false, timer: 3500 });
                                }).catch( err => {
                                    this.handleModal();
                                    this.initialize();
                                    this.$swal.fire({ position: 'top-end', icon: 'success', title: 'Entrada creada correctamente', text: resp.data.message, showConfirmButton: false, timer: 3500 });
                                    // this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error, entrada no registrado', html: err.response.data, showConfirmButton: false, timer: 3500 });
                                })
                            } else {
                                this.handleModal();
                                this.initialize();
                                this.$swal.fire({ position: 'top-end', icon: 'success', title: 'Entrada creada correctamente', text: resp.data.message, showConfirmButton: false, timer: 3500 });
                            }
                        }
                    }).catch( err => {
                        this.$swal.fire({ position: 'top-end', icon: 'error', title: 'Error, entrada no registrado', html: err.response.data, showConfirmButton: false, timer: 3500 });
                    });
                }
            }).catch ( err => {
                this.$swal.fire({ position: 'top-end', icon: 'warning', title: 'Error, entrada no registrado, validar campos', text: err.message, showConfirmButton: false, timer: 3500 });
            })
        },
        addEntradaProduct( data ){
            this.entrada.entradaProduct = data;
            const total = data.reduce( (total, item) => total + parseFloat(item.importe), 0 );
            this.entrada.total = total;
            this.totalNatural = total.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        },
        onProviderCredit(creditProvider){
            this.creditProvider = creditProvider;
        }
    },
    watch: {
        'entrada.tipoEntradaId': function(newValue){
            this.entrada.origenTrasladoId = null;
            this.$refs.myEntryProduct.entradaProduct = [];
            if( newValue == 2 || newValue == 3 ){
                this.changeTipoEntrada();
                this.listVentaTraslado(this.entrada.sucursalRecibeId);
            }
        },
        'entrada.sucursalRecibeId': function(newValue){
            if( newValue != null ){
                this.$refs.myEntryProduct.entradaProduct = [];
                this.listVentaTraslado(newValue);
            }
        },
        'entrada.origenTrasladoId': function(newValue){
            if( newValue > 0 ){
                this.chargeProductToEntry(newValue);
            }
            if( newValue == null ){
                this.$refs.myEntryProduct.entradaProduct = [];
            }
        }
    },
    computed: {
        isAdmin(){ return UsuarioDataService.getUserRoles().includes('ROLE_ADMIN'); },
        computedDate() { return getFullDateTodayNatural() + ' ' + getHourNowNatural(); },
        modeBuy(){ return this.entrada.tipoEntradaId == 1; },
        modeTraspaso(){ return this.entrada.tipoEntradaId == 2; },
        checkProvider(){
            return this.entrada.proveedorId == this.creditProvider.idProveedor;
        }
    }
}
</script>
<style>
    .calendario{
        text-transform: capitalize;
        padding : 0px;
    }
</style>