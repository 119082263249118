import { http, authHeader } from '@/http-commons';

class VentaDetalleDataService {

    showDetailsVenta( ventaId ){
        return http.get(`api/v1/ventaDetalle/showDetails/${ventaId}`,{ headers: authHeader()});
    }

    graficaVenta(tipoSalidaId){
        return http.get(`api/v1/ventaDetalle/graficaVenta/${tipoSalidaId}`, { headers: authHeader()} );
    }
    downloadVentaDetailExcel(params){
        return http.get("/api/v1/ventaDetalle/downloadVentaDetalleExcelByRange", {params, responseType: 'blob', headers: authHeader()});   
    }
}
export default new VentaDetalleDataService();